import styled, { css } from "styled-components";
import Img, { FluidObject } from "gatsby-image";

import { device } from "../../../../../../utils/breakpoints";
import {
  fadeIn,
  fadeOut,
  slideFromLeft,
  oAnimation,
  kAnimation,
  eAnimation,
} from "../../../../../../utils/animations";
import {
  H2,
  H3,
  SUBTITLE_2,
  TEXT_BODY_2,
} from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 88px 178px 20px 88px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 600ms linear
        `
      : css`
          ${fadeIn} 600ms linear
        `};
  animation-fill-mode: forwards;

  @media ${device.computer} {
    padding: 128px 120px 120px 120px;
    flex-direction: row;
  }

  @media ${device.desktop} {
    padding: 128px 120px 120px 120px;
  } ;
`;

export const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  &:first-of-type {
    flex: 1;
  }

  @media ${device.computer} {
    display: grid;
    grid-template-columns: repeat(12, 64px);
    grid-column-gap: 32px;
  }

  @media ${device.desktop} {
    grid-template-columns: repeat(12, 110px);
  } ;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding-right: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.computer} {
    padding-right: 0;
    display: grid;
    grid-column: 1 / 6;
  }

  @media ${device.desktop} {
    grid-column: 1 / 5;
  } ;
`;

export const Title = styled.div`
  display: inline-block;
  margin: 0;
  font-size: 2.2rem;
  line-height: 2.5rem;
  font-weight: 700;
  color: #151515;
  white-space: pre-line;

  &:first-child {
    color: #013cff;
    opacity: 0;
    animation: ${fadeIn} 0.6s linear;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
  }

  &:nth-child(2) {
    opacity: 0;
    animation: ${fadeIn} 0.6s linear;
    animation-delay: 0.9s;
    animation-fill-mode: forwards;
  }

  @media ${device.computer} {
    ${H3}
  }

  @media ${device.desktop} {
    ${H2}
  } ;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  margin: 12px 0 0 0;
  display: block;
  color: #151515;
  white-space: pre-line;
  transform: translateX(-200%);
  animation: ${slideFromLeft} 0.6s ease-out;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
`;

export const DescriptionTitle = styled.span`
  ${SUBTITLE_2}
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media ${device.tablet} {
    &:nth-of-type(3) {
      margin-bottom: 40px;
    }
  }

  @media ${device.computer} {
    &:nth-of-type(3) {
      margin-bottom: 36px;
    }
  }
`;

export const ContactWrapper = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transform: translateX(-200%);
  animation: ${slideFromLeft} 0.6s ease-out;
  animation-delay: 1.6s;
  animation-fill-mode: forwards;
`;

export const HorizontalDivider = styled.hr`
  margin: 4vh 0 4vh 0;
  width: 112px;
  height: 6px;
  background: #013cff;

  @media ${device.computer} {
    margin: 98px 0 32px 0;
    width: 65px;
  }

  @media ${device.desktop} {
    margin: 150px 0 32px 0;
    width: 112px;
  } ;
`;

export const ContactInformation = styled.div`
  width: auto;
  height: 100%;
  margin: 0;

  &:first-of-type {
    margin-right: 4vw;
  }
`;

export const ContactTitle = styled.div`
  ${SUBTITLE_2}
  margin: 0;
  font-weight: 600;
  color: #151515;
`;

export const ContactDescription = styled.div`
  ${TEXT_BODY_2}
  margin: 0;
  color: #151515;
  white-space: pre-line;
`;

export const LettersWrapper = styled.div`
  position: relative;
  flex: 1;
  min-height: 36vw;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: -1;

  @media ${device.computer} {
    margin-top: 220px;
    display: grid;
    grid-column: 7 / 12;
    min-height: 0;
    align-items: flex-start;
  }

  @media ${device.desktop} {
    grid-column: 6 / 12;
  } ;
`;

export const LettersItem = styled.div`
  @media ${device.tablet} {
    position: relative;
    flex: 1;
    min-height: 36vw;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: -1;
  }

  @media ${device.computer} {
    display: flex;
  }
`;

export const Image = styled(Img)<{
  fluid: FluidObject | FluidObject[];
}>`
  display: block;
  width: 30%;
  max-height: 80vh;
  margin: 0 2vw;

  img {
    object-fit: contain !important;
  }

  &:first-of-type {
    animation: ${oAnimation} 16s linear infinite;
  }

  &:nth-of-type(2) {
    animation: ${kAnimation} 16s linear infinite;
  }

  &:nth-of-type(3) {
    animation: ${eAnimation} 16s linear infinite;
  }

  @media ${device.computer} {
    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const Image2 = styled(Img) <{
    fluid: FluidObject | FluidObject[];
}>`
  display: block;
  width: 30%;
  max-height: 80vh;
  margin: 0 2vw;

  img {
    object-fit: contain !important;
  }

  &:first-of-type {
  }

  &:nth-of-type(2) {
  }

  &:nth-of-type(3) {
  }

  @media ${device.computer} {
    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;
