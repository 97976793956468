import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  Grid,
  ContentWrapper,
  Title,
  Description,
  DescriptionTitle,
  HorizontalDivider,
  RowWrapper,
  ContactWrapper,
  ContactInformation,
  ContactTitle,
  ContactDescription,
  LettersWrapper,
  LettersItem,
  Image,
  Image2,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import Breakpoint from "../../../../../common/Breakpoint";
import { FluidImageType } from "../../../../../../utils/types";
import { splitStringAt } from "../../../../../../utils/common";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../common/PageTitle";

interface LettersOKEProps {
  pageChange: PageChange;
}

const LettersOKE: React.FC<LettersOKEProps> = ({ pageChange }) => {
  const { t } = useTranslation("main");
  const i18nContact = useTranslation("contact");
  const data = useStaticQuery(lettersQuery);

  return (
    <>
      <PageTitle subtitle={t(`values_title`)} />
      <PageWrapper animation={pageChange}>
        <Grid>
          <ContentWrapper>
            <RowWrapper>
              {splitStringAt(t(`letters_o`), 1).map((string, index) => (
                <Title key={index}>{string}</Title>
              ))}
            </RowWrapper>
            <RowWrapper>
              {splitStringAt(t(`letters_k`), 1).map((string, index) => (
                <Title key={index}>{string}</Title>
              ))}
            </RowWrapper>
            <RowWrapper>
              {splitStringAt(t(`letters_e`), 1).map((string, index) => (
                <Title key={index}>{string}</Title>
              ))}
            </RowWrapper>

            <Breakpoint device="tabletLandscape">
              <LettersWrapper>
                {data.allFile.nodes.map(
                  (image: FluidImageType, index: number) => (
                    <Image key={index} fluid={image.childImageSharp.fluid} />
                  )
                )}
              </LettersWrapper>
            </Breakpoint>
            <Description>{t(`letters_description`)}
              <Description>
                <DescriptionTitle>{t(`letters_o`)}</DescriptionTitle>
                {t(`o_description`)}
              </Description>
              <Description>
                <DescriptionTitle>{t(`letters_k`)}</DescriptionTitle>
                {t(`k_description`)}
              </Description>
              <Description>
                <DescriptionTitle>{t(`letters_e`)}</DescriptionTitle>
                {t(`e_description`)}
              </Description>
            </Description>
            <ContactWrapper>
              <HorizontalDivider />
              <RowWrapper>
                <ContactInformation>
                  <ContactTitle>{i18nContact.t(`oke_title`)}</ContactTitle>
                  <ContactDescription>
                    {i18nContact.t(`oke_location`)}
                  </ContactDescription>
                </ContactInformation>
              </RowWrapper>
            </ContactWrapper>
          </ContentWrapper>

          <Breakpoint device={["tablet", "computer"]}>
            <LettersWrapper>
              <LettersItem>
                {data.allFile.nodes.map((image: FluidImageType, index: number) => (
                  <Image2 key={index} fluid={image.childImageSharp.fluid} />
                ))}
              </LettersItem>
            </LettersWrapper>
          </Breakpoint>
        </Grid>
      </PageWrapper>
    </>
  );
};

const lettersQuery = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "okeLetters" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default LettersOKE;
