import React from "react";
import ReactDOMServer from 'react-dom/server';

interface NumberToFixedLengthProps {
  number: number;
  length: number;
  padding?: string;
};

export const numberToFixedLength = ({ 
  number, 
  length, 
  padding = '0' 
}: NumberToFixedLengthProps) => 
  number.toString().padStart(length, padding)

interface FilterArrayOfObjectsProps {
  array: any[];
  property: string;
  value: any;
};

export const filterArrayOfObjects = ({
  array,
  property,
  value
}: FilterArrayOfObjectsProps) => 
  array.filter((item) => item[property] === value).length > 0;

export const splitStringAt: (value: string, index: number) => string[] = (value, index) => 
  [value.substring(0, index), value.substring(index)];

export const replaceWithJSX = (
  str: string, 
  find: RegExp, 
  replace: JSX.Element,
  returnAsHTMLString?: boolean
) => {
  const parts = str.split(find);
  const result = 
    [parts[0], 
    returnAsHTMLString ? ReactDOMServer.renderToStaticMarkup(replace) : replace, 
    parts[1]
  ];

  return result;
};